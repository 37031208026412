import React, { useState } from 'react'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { motion } from 'framer-motion'

import { images } from '../../constants'
import { AppWrap } from '../../Wrapper'
import './Work.scss'

const Work = () => {
  const worksConst = [
    {
      title: 'Work 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      projectLink: 'https://github.com',
      codeLink: 'https://github.com',
      imgUrl: images.about01,
      tags: ["Header 1", "All"]
    },
    {
      title: 'Work 2',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      projectLink: 'https://github.com',
      codeLink: 'https://github.com',
      imgUrl: images.about02,
      tags: ["Header 2", "All"]
    },
    {
      title: 'Work 3',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      projectLink: 'https://github.com',
      codeLink: 'https://github.com',
      imgUrl: images.about03,
      tags: ["Header 3", "All"]
    },
    {
      title: 'Work 4',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      projectLink: 'https://github.com',
      codeLink: 'https://github.com',
      imgUrl: images.about04,
      tags: ["Header 4", "All"]
    }
  ]

  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({y: 0, opacity: 1});
  const [filterWork, setFilterWork] = useState(worksConst)

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({y: 100, opacity: 1});

    setTimeout(() => {
      setAnimateCard({y: 0, opacity: 1});

      console.log(item)

      if (item === 'All') {
        setFilterWork(worksConst)
      } else {
        setFilterWork(worksConst.filter((work) => work.tags.includes(item)))
      }
    }, 500)
  }

  return (
    <>
      <h2 className="head-text">
        Lorem  
        <span> Sit Amet,</span>
        <br />
        Sit
        <span> Elit, Sed</span>
      </h2>

      <div className="app__work-filter">
        {['Header 1', 'Header 2', 'Header 3', 'Header 4', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={work.imgUrl} alt={work.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__work-content app__flex">
              <h4 className="bold-text">
                {work.title}
              </h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>
              <div className="app__work-tag app__flex">
                <p className="p-text">
                  {work.tags[0]}
                </p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  )
}

export default AppWrap(Work, 'Work')