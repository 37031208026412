import React from 'react'
import { motion } from 'framer-motion'

import { AppWrap } from '../../Wrapper'
import { images } from '../../constants'

import './About.scss'

const abouts = [
  { 
    title: 'About 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    imgUrl: images.about01
  },
  { 
    title: 'About 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    imgUrl: images.about02
  },
  { 
    title: 'About 3',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    imgUrl: images.about03
  },
];

const About = () => {
  return (
    <>
      <h2 className="head-text">
        Lorem Ipsum Dolor 
        <span> Sit Amet,</span>
        <br />
        Adipiscing
        <span> Elit, Sed Do Eiusmod</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}          
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={about.imgUrl} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <h2 className="p-text" style={{ marginTop: 10 }}>{about.description}</h2>
          </motion.div>
        ))}
      </div>
    </>
  )
}

export default AppWrap(About, "about")