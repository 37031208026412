import React, { useState } from 'react'
import { motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'

import { images } from '../../constants'
import { AppWrap } from '../../Wrapper'
import './Skills.scss'

const Skills = () => {
  const [experience, setExperience] = useState(
    [
      {

      }
    ]
  )

  const [skills, setSkills] = useState(
    [
      {
        name: 'Skill 1',
        icon: images.html,
        bgColor: '#f5f5f5',
      },
      {
        name: 'Skill 2',
        icon: images.css,
        bgColor: '#2f2f2f',
      },
      {
        name: 'Skill 3',
        icon: images.git,
        bgColor: '#lf2f2f',
      },
      {
        name: 'Skill 4',
        icon: images.python,
        bgColor: '#0f2f2f',
      }
    ]
  )

  return (
    <>
      <h2 className="head-text">
        Skills & Experience
      </h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item"
              key={skill.name}
            >
              <div className="app__flex" style={{ backgroundColor: skills.bgColor }}>
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  )
}

export default Skills